.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*------------------------------------------------------------------
[6. Widget / .widget]
*/

.widget {
  position: relative;

  margin-bottom: 2em;
  margin-right: 1em;

  overflow: hidden;
}

.widget-header {
  position: relative;

  height: 40px;
  line-height: 40px;

  background: #e9e9e9;
  background: -moz-linear-gradient(top, #fafafa 0%, #e9e9e9 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fafafa),
    color-stop(100%, #e9e9e9)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #fafafa 0%,
    #e9e9e9 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #fafafa 0%,
    #e9e9e9 100%
  ); /* Opera11.10+ */
  background: -ms-linear-gradient(top, #fafafa 0%, #e9e9e9 100%); /* IE10+ */
  background: linear-gradient("to top", #fafafa 0%, #e9e9e9 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9');
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9')";

  border: 1px solid #d5d5d5;

  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.widget-header h3 {
  position: relative;
  top: 2px;
  left: 10px;

  display: inline-block;
  margin-right: 3em;

  font-size: 14px;
  font-weight: 800;
  color: #555;
  line-height: 18px;

  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);

  font: 13px/1.7em "Open Sans";
}

.widget-header [class^="icon-"],
.widget-header [class*=" icon-"] {
  display: inline-block;
  margin-left: 13px;
  margin-right: -2px;

  font-size: 13px;
  color: #555;
  vertical-align: middle;
}

.widget-header {
  font: 13px/1.7em "Open Sans";
  position: relative;
  height: 40px;
  line-height: 40px;
  background: -webkit-linear-gradient(top, #fafafa 0%, #e9e9e9 100%);
}

.widget-content {
  padding: 20px 15px 15px;

  background: #fff;

  border: 1px solid #d5d5d5;

  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.widget-header + .widget-content {
  border-top: none;

  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.widget-nopad .widget-content {
  padding: 0;
}

/* Widget Content Clearfix */
.widget-content:before,
.widget-content:after {
  content: "";
  display: table;
}

.widget-content:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.widget-content {
  zoom: 1;
}

/* Widget Table */

.widget-table .widget-content {
  padding: 0;
}

.widget-table .table {
  margin-bottom: 0;

  border: none;
}

.widget-table .table tr td:first-child {
  border-left: none;
}

.widget-table .table tr th:first-child {
  border-left: none;
}

/* Widget Plain */

.widget-plain {
  background: transparent;

  border: none;
}

.widget-plain .widget-content {
  padding: 0;

  background: transparent;

  border: none;
}

/* Widget Box */

.widget-box .widget-content {
  background: #e3e3e3;
  background: #fff;
}

.span5 {
  width: 470px;
}

/*------------------------------------------------------------------
[1. Shortcuts / .shortcuts]
*/

.shortcuts {
  text-align: center;
}

.shortcuts .shortcut {
  width: 22.5%;
  display: inline-block;
  padding: 12px 0;
  margin: 0 0.9% 1em;
  vertical-align: top;

  text-decoration: none;

  background: #f3f3f3;

  border-radius: 5px;
}

.shortcuts .shortcut .shortcut-icon {
  margin-top: 0.25em;
  margin-bottom: 0.25em;

  font-size: 32px;
  color: #888;
}

.shortcuts .shortcut:hover {
  background: #e8e8e8;
}

.shortcuts .shortcut:hover .shortcut-icon {
  color: #666;
}

.shortcuts .shortcut-label {
  display: block;

  font-weight: 400;
  color: #666;
}

#big_stats {
  width: 100%;
  display: table;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.big-stats-container .widget-content {
  background: #e9e9e9;
  background: -moz-linear-gradient(top, #fafafa 0%, #e9e9e9 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fafafa),
    color-stop(100%, #e9e9e9)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #fafafa 0%,
    #e9e9e9 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #fafafa 0%,
    #e9e9e9 100%
  ); /* Opera11.10+ */
  background: -ms-linear-gradient(top, #fafafa 0%, #e9e9e9 100%); /* IE10+ */
  background: linear-gradient("to top", #fafafa 0%, #e9e9e9 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9');
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FAFAFA', endColorstr='#E9E9E9')";
}

#big_stats .stat {
  width: 25%;
  height: 90px;
  text-align: center;
  display: table-cell;
  padding: 0;
  position: relative;

  border-right: 1px solid #ccc;
  border-left: 1px solid #fff;
}

#big_stats .stat:first-child {
  border-left: none;
}

#big_stats .stat:last-child {
  border-right: none;
}

#big_stats .stat h4 {
  font-size: 11px;
  font-weight: bold;
  color: #777;
  margin-bottom: 1.5em;
}

#big_stats .stat .value {
  font-size: 48px;
  font-weight: bold;
  color: #333;
  line-height: 1em;
}

/* .dx-datagrid{  
    font:11px verdana;  
}  
.dx-box-item-content {
    font:11px verdana;  
} */

.error .dx-field-item-help-text {
  color: red;
  font-weight: bold;
  font-size: 12px;
}